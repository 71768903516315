module.exports = {
	/////// NAVIGATION////////
	"origensLink": "/es/origens",
	"origensLabel": "Orígenes",
	"cuinaLink": "/es/cuina",
	"cuinaLabel": "Cocina",
	"espaisLink": "/es/espais",
	"espaisLabel": "Espacios",
	"cellerLink": "/es/celler",
	"cellerLabel": "Bodega",
	"cartaLink": "/es/carta",
	"cartaLabel": "Carta",
	"noticiesLink": "/es/noticies",
	"noticiesLabel": "Noticias",
	"contacteLink": "/es/contacte",
	"contacteLabel": "Contacto",
	"reservesLink": "/es/reserves",
	"reservesLabel": "Reservas",

	/////// FOOTER////////
	"direccioCarrer": "Ponent 20",
	"direccioPoblacio": "Sant Pau d'Ordal 08739 BCN",
	"labelGoogleMaps": "google maps",
	"numTelefon":"+34 93 899 30 28",
	"email": "info@calperedelmaset.com",
	"diesDinarOberts": "Martes a Domingo",
	"horesDinarOberts": "13:00 a 16:00",
	"diesSoparOberts": "Viernes y Sábado",
	"horesSoparOberts": "21:00h a 23:00h",
	"diaTancat": "Lunes cerrado",
	"segueixnos": "SÍGUENOS",
	"treballaAmbNosaltres": "Trabaja con nosotros",
	"privacitat": "PRIVACIDAD",
	"condicionsDus": "CONDICIONES DE USO",

	/////// ORIGENS////////
	"heroText": "",
	"sentenceText": "",
	"contentCaption": "",
	"contentTitle": "",
	"contentContent": "",
	"textSectionText": ""
}