import React, { useState } from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Layout from "../components/layout/es"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import WeeklyMenu from "../components/restaurant-menu/weekly-menu"

const MenuPage = (props) => {
	const seoDescription = "Para celebrar el 50 aniversario de nuestra casa, de martes a viernes os proponemos un menú donde podrán probar platos de temporada que nos han acompañado a lo largo de éstos años.";
	const entrants = "Entrantes";
	const platsPrincipals = "Platos Principales";
	const postres = "Postres";
	const ivaInclos = "IVA incluido";
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Menú de entre semana"
	    	lang="es"
	    	description={seoDescription}
	    />
	    <HeroImage 
	      titleText="Menú de entre semana"
	      image="carta" 
	    />
	    <WeeklyMenu 
	    	lang='es'
	    	entrants={entrants}
	    	principals={platsPrincipals}
	    	postres={postres}
	    	iva={ivaInclos}
	    />
	    <Link to="/es/carta">
	    	<h2>Ver la Carta</h2>
	    </Link>
	  </Layout>
	)
}

const MenuSection = styled.div`
	margin: 140px 70px;
	display: flex;
	@media (max-width: 540px){
		margin: 100px 5px;
	  flex-direction: column;
	}
`

const SelectBox = styled.select`
	font-size: 16px;
	@media (min-width: 540px){
	  display: none;
	}
`

const MenuHeaders = styled.div`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	width: 250px;
	li {
	  cursor: pointer;
	  padding: 10px;
	  line-height: 20px;
	}
	@media (max-width: 540px){
	  display: none;
	}
`

export default MenuPage